<template>
  <noscript v-if="isClient && shouldLoadGTM">
    <iframe :src="`${googleTagManagerDomain}/ns.html?id=${googleTagManagerId}`" data-tc="gtm-iframe"
    title="Google Tag Manager" height="0" width="0" style="display:none;visibility:hidden;"></iframe>
  </noscript>
</template>

<script>
import { mapState } from 'pinia'

import { useSessionStore } from '../../stores/session'
import { useSourcepointStore } from '../../stores/sourcepoint'
import { useConfigStore } from '../../stores/config'
import clientOnly from '../../mixins/client-only'

export default {
  mixins: [clientOnly],
  data () {
    return {
      gtmScriptInjected: false,
      consentProperties: [
        'ad_user_data',
        'ad_personalization',
        'ad_storage',
        'analytics_storage',
        'functionality_storage',
        'security_storage',
        'personalization_storage'
      ]
    }
  },
  computed: {
    ...mapState(useSourcepointStore, ['consentsExist']),
    ...mapState(useSessionStore, ['hasPurSub']),
    ...mapState(useConfigStore, ['rsConfig']),
    googleTagManagerId () { return this.rsConfig.googleTagManagerId },
    googleTagManagerDomain () {
      // Clarified with Martina Sekuloska, the standard domain should be used for browser integration, not our custom domains
      return 'https://www.googletagmanager.com'
    },
    shouldLoadGTM () {
      // GTM should be loaded AFTER consent or if PUR sub exists - just dont load it if consent window is active & do not use tag manager on development
      // We do not check for 'noScripts' from sourcepointStore here, because we want to load the scripts on whitelist pages to enable tracking
      return (this.consentsExist || this.hasPurSub) && this.googleTagManagerId && process.env.NODE_ENV !== 'development'
    }
  },
  watch: {
    consentsExist () {
      this.injectGtmScripts()
    }
  },
  beforeMount () {
    window.dataLayer = window.dataLayer || []
    // eslint-disable-next-line prefer-rest-params
    window.gtag = function () { dataLayer.push(arguments) }
    // ^^ this is from the official docs  - https://developers.google.com/tag-platform/security/guides/consent?consentmode=basic#tag-manager
    this.updateConsent('default', 'denied', { wait_for_update: 500 })
    // No script initialization as documented by Google;
    // this is done by our colleagues in Tag Manager.
    // i.e. window.gtag('js', new Date()); window.gtag('config', this.googleTagManagerId)
    // Otherwise, we generate errors in the Tag Manager console.
    this.injectGtmScripts()
  },
  mounted () {
    // while we need tracking on whitelisted page - there where the user can remove consent via sourcepoint -
    // we need to check if the user has removed consent, so we can send updates to gtm and remove the scripts
    window.__tcfapi('addEventListener', 2, (tcData, success) => {
      if (success && tcData.eventStatus === 'useractioncomplete') {
        // if there are 0 consents, it means the user has not consented OR clicked the "ALLE EINWILLIGUNGEN WIDERRUFEN" button via the datenschutzmanager
        const hasConsented = Object.values(tcData.vendor.consents).length
        if (!hasConsented) {
          this.updateConsent('update', 'denied')
          this.removeScripts()
        }
      }
    })
  },
  methods: {
    /**
     * Generates a consent object with all required properties set to the given value.
     * Additional properties (e.g., wait_for_update) can be merged in via extraProperties.
     *
     * @param {string} value - The consent status (e.g., 'granted' or 'denied').
     * @param {Object} extraProperties - Optional additional properties to include in the object.
     * @returns {Object} - The final consent object.
     */
    getConsentProperties (value, extraProperties = {}) {
      const consentObject = this.consentProperties.reduce((acc, key) => {
        acc[key] = value
        return acc
      }, {})

      return { ...consentObject, ...extraProperties }
    },
    /**
     * Updates Google Tag Manager consent settings.
     *
     * @param {string} mode - The GTM consent mode ('default' for initial setup, 'update' for later changes).
     * @param {string} value - The consent status (e.g., 'granted' or 'denied').
     * @param {Object} extraProperties - Optional additional properties to be included (e.g., { wait_for_update: 500 }).
     */
    updateConsent (mode = 'update', value, extraProperties = {}) {
      window.gtag('consent', mode, this.getConsentProperties(value, extraProperties))
    },
    injectGtmScripts () {
      if (this.shouldLoadGTM) {
        this.updateConsent('update', 'granted')

        if (!this.gtmScriptInjected) {
          /* eslint-disable */
          (function(w,d,s,l,i,gtmd){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          gtmd + '/gtm.js?id='+i+dl;j.dataset.tc='gtm-script';f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', this.googleTagManagerId, this.googleTagManagerDomain)
          /* eslint-enable */
          this.gtmScriptInjected = true
        }
      } else {
        this.removeScripts()
      }
    },
    removeScripts () {
      // if should not load gtm but we already did (e.g. user removed consent in sourcepoint) we update the datalayer and remove all that shieeeeeeeet
      if (this.gtmScriptInjected) {
        // remove gtm scripts
        const scripts = document.querySelectorAll('script[src*="gtm"]')
        scripts?.forEach((script) => script.remove())
        // let's ensure that there is no iframe left over
        const iframes = document.querySelectorAll('iframe[data-usage="gtm"]')
        iframes?.forEach((iframe) => iframe.remove())
        // revert injected state
        this.gtmScriptInjected = false
      }
    }
  }
}
</script>
